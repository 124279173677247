<template>
  <div class="mode_container">
    <el-row class="mode_header">
      <el-col :span="24">选择支付方式</el-col>
    </el-row>
    <Count></Count>
    <el-row class="mode_title">
      <h1>温馨提示：请先选择支付方式</h1>
    </el-row>
    <div class="mode_main">
      <div class="wechat" @click="pay(query)">
        <img src="../assets/wx.png" alt="" />
        <p>微信支付</p>
      </div>
      <!-- <div class="insurance" @click="medical(query)">
        <img src="../assets/chs.png" alt="">
        <p>医保支付</p>
      </div> -->
      <!-- <div class="zfb">
        <img src="../assets/zfb.png" alt="">
        <p>支付宝支付</p>
      </div>
      <div class="unionPay">
        <img src="../assets/yl.png" alt="">
        <p>银联支付</p>
      </div>
      -->
    </div>
    <el-row class="mode_tip">
      <el-col :span="24">
        <span>{{ patName }}</span
        >&nbsp;&nbsp;您好&nbsp;&nbsp;欢迎使用自助终端，请您使用结束后退出首页&nbsp;&nbsp;如非本人，请退出首页，谢谢！
      </el-col>
    </el-row>
    <el-button type="primary" @click.native="back" class="back">返回</el-button>
    <el-button type="primary" @click.native="home" class="home">首页</el-button>
    <Foo></Foo>
  </div>
</template>
<script>
import Foo from './footer/index'
import Count from './count/index'

import { jsonPost, formPost } from '@/baseAPI'
import { outPatientPreSet } from '@/service/outpatient'
export default {
  components: {
    Foo,
    Count
  },
  data() {
    return {
      deptName: '',
      name: '',
      patName: '',
      query: {},
      imgurl: ''
    }
  },
  created() {
    this.name = this.$route.query.name
    this.patName = localStorage.getItem('name')
    this.query = this.$route.query
    if (this.name == '住院按金') {
      this.hospitalDepositPayment()
    } else if (this.name == '门诊缴费') {
      this.payUnifiedOrder()
    } else if (this.name == '预约挂号' || this.name == '当天挂号') {
      // this.regUnifiedOrder()
    }
  },
  methods: {
    // 住院按金补缴支付
    async hospitalDepositPayment() {
      const loading = this.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      const { data: res } = await jsonPost('/his/hospitalDepositPayment', {
        admissionNum: this.query.admissionNum,
        cost: this.query.regFee
        // cost: '0.01'
      })
      if (res.code === 0) {
        loading.close()
        this.query.qrCode = res.data.qrCode
        this.query.outTradeNo = res.data.outTradeNo
      } else {
        loading.close()
        this.$message({
          message: res.msg,
          type: 'error'
        })
      }
    },
    // 门诊缴费
    async payUnifiedOrder() {
      const loading = this.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      const { data: res } = await outPatientPreSet(this.$route.query)
      if (res.data.code == 0) {
        loading.close()
        this.query.qrCode = res.data.qrCode
        this.query.jump = res.data.jump
        this.query.outTradeNo = res.data.outTradeNo
      } else {
        loading.close()
        this.$message({
          message: res.msg,
          type: 'error'
        })
      }
    },
    // 挂号
    // regUnifiedOrder () {
    //   let _this = this
    //   const params = {
    //     patCardType: this.query.patCardType,
    //     regFee: this.query.regFee,
    //     orderNum: this.query.orderNum,
    //     recordType: "1",
    //     appointmentId: this.query.appointmentId,
    //     orderRecordId: this.query.orderRecordId,
    //     outTradeNo: this.query.outTradeNo
    //   }
    //   Promise.all([_this.$api.unifiedOrder(params)]).then((data) => {
    //     this.query.qrCode = data[0].data.qrCode
    //     this.query.outTradeNo = data[0].data.outTradeNo
    //   });
    // },

    back() {
      this.$router.back()
    },
    home() {
      this.$router.push('/home')
    },
    pay(query) {
      console.log(query)
      this.$router.push({
        path: '/qrCodePay',
        query
      })
    },
    medical(query) {
      console.log(query)
      this.$router.push({
        path: '/medical',
        query
      })
    }
  }
}
</script>
<style>
.mode_header {
  background-color: #81ace4;
  height: 100px;
  font-size: 38px;
  font-weight: 700;
  line-height: 100px;
  color: white;
  letter-spacing: 20px;
  padding-left: 30px;
}

.mode_title h1 {
  /* margin: 30px 0; */
  margin-top: 10%;
  font-weight: 700;
  color: #80d0c7;
  text-align: center;
  letter-spacing: 10px;
  font-size: 60px;
}

.mode_main {
  margin: 50px auto;
  width: 75%;
}

.mode_main div {
  float: left;
  width: 450px;
  height: 300px;
  color: white;
  font-size: 30px;
  background-color: #dcdfe6;
  border-radius: 6px;
  margin: 50px 80px;
  padding: 10px 20px;
}

.mode_main div img {
  width: 200px;
  height: 200px;
  float: left;
  margin-top: 30px;
}

.mode_main div p {
  float: left;
  line-height: 180px;
  margin-left: 30px;
  font-size: 38px;
}

.mode_tip {
  position: fixed !important;
  bottom: 36px;
  left: 0;
  z-index: 1;
  padding-left: 30px;
  color: red;
  font-size: 20px;
}

.mode_tip span {
  color: blue;
}

.mode_footer {
  position: fixed !important;
  bottom: 0;
  left: 0;
  z-index: 1;
  height: 30px;
  width: 100%;
  background-color: #81ace4;
  font-size: 14px;
  color: white;
  text-align: center;
  line-height: 30px;
}

.back {
  width: 220px;
  height: 120px;
  position: absolute;
  bottom: 80px;
  right: 270px;
  font-size: 35px !important;
}

.home {
  width: 220px;
  height: 120px;
  position: absolute;
  bottom: 80px;
  right: 30px;
  font-size: 35px !important;
}
</style>
